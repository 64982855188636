@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600&family=Playfair+Display:wght@400;600&display=swap');

  @import url('https://fonts.googleapis.com/css2?family=Euphoria+Script&family=Inter:wght@400;600;700&display=swap');


@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --person-1: url('/src/assets/person-1.PNG');
  --person-2: url('/src/assets/person-2.PNG');
  --person-3: url('/src/assets/person-3.PNG');
  --person-4: url('/src/assets/person-4.PNG');
  --person-5: url('/src/assets/person-5.PNG');
  --person-6: url('/src/assets/person-6.PNG');
  --person-7: url('/src/assets/person-8.PNG');
  --person-8: url('/src/assets/person-8.PNG');
  --person-9: url('/src/assets/person-9.PNG');
}

html,
body,
#root,
.app {

    height: 100%;
    background-color: #fff1d6;
    @apply text-black;

}